import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Scene, LazyPriority } from './Scene';
import DelayedComponent from 'app/components/common/lazy/delayed';

export const JoinUsThreeAnimation = (props: { active?: boolean, bgColor?: boolean }) => {
    return (
        <Suspense fallback={null}>
            <Canvas id="join-us-3d" flat linear>
                <DelayedComponent lazyPriority={LazyPriority - 1}>
                    <Scene visible={props.active} bgColor={props.bgColor} />
                </DelayedComponent>
            </Canvas>
        </Suspense>
    );
};
