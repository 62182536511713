import { OrthographicCamera } from '@react-three/drei';
import { useLayoutEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { observer } from 'mobx-react-lite';
import { Rotator } from 'scripts/animations/rotator';
import { JoinUsThreeAnimationBridge } from './bridge';
import { useBreakpointType, BreakpointType } from 'app/services/breakpoints';
import { CuratorNetwork } from 'components/sections/home/experience/animation/icons/2_curator';
import { BackgroundColor } from 'components/sections/home/experience/animation/icons/common/materials';
import {
    AmbientLight,
    PrimaryLight,
    SecondaryLight,
    SecondaryLight2,
} from 'components/sections/home/experience/animation/icons/common/lights';
import { DirectionalLightEcho, HemisphereLightEcho } from 'scripts/animations/echos';
import { PreCompiler } from 'scripts/animations/preCompiler';
import { createManualPromise } from '@zajno/common/async/misc';
import { LazyQueue } from 'scripts/components/lazy/lazyLoadComponent';

type Props = JSX.IntrinsicElements['group'] & {
    bgColor?: boolean;
};

const MainScale: Record<BreakpointType, number> = {
    [BreakpointType.Desktop]: 200,
    [BreakpointType.Tablet]: 200,
    [BreakpointType.Mobile]: 120,
};

export const LazyPriority = 8;
const PrecompilePromise = createManualPromise();

LazyQueue.enqueue(() => PrecompilePromise.promise, LazyPriority);

export const Scene = observer((allProps: Props) => {

    const isActive = JoinUsThreeAnimationBridge.isActive.value;

    const { bgColor, ...props } = allProps;
    const mainVisible = props.visible ?? isActive;

    const rootRef = useRef<THREE.Group>();
    const rotateTargetRef = useRef<THREE.Group>();

    const { type: breakpointType } = useBreakpointType();

    const scene = useThree(state => state.scene);

    useLayoutEffect(() => {
        scene.background = bgColor ? BackgroundColor : null;
    }, [scene, bgColor]);

    return (
        <group
            {...props}
            ref={rootRef}
            dispose={null}
            visible={mainVisible}
        >
            <PreCompiler
                name='JoinThreeAnimation'
                rootRef={rootRef}
                manualPromise={PrecompilePromise}
                withLazyPriority={LazyPriority - 1}
            />

            {mainVisible && <Rotator target={rotateTargetRef} scale={0.3} />}

            <DirectionalLightEcho source={PrimaryLight} />
            <DirectionalLightEcho source={SecondaryLight} />
            <DirectionalLightEcho source={SecondaryLight2} />
            <HemisphereLightEcho source={AmbientLight} />

            <OrthographicCamera
                name="Camera"
                makeDefault={true}
                zoom={5}
                far={100000}
                near={-100000}
                position={[0, 0.91, 1000]}
                rotation={[0, 0, 0]}
            />
            <group
                name="All_Icons_static"
                scale={MainScale[breakpointType]}
                ref={rotateTargetRef}
            >
                <group name="Scene_0" rotation={[-Math.PI, 0, -Math.PI]}>
                    <CuratorNetwork final />
                </group>
            </group>
        </group>
    );
});
